/**
 * @fileoverview Getting logged in status for use in determining which page to redirect them to.
 *
 * @author Norbert Chu (nchu@yext.com)
 */
goog.module('yext.surveyserving.FindLoggedIn');
goog.module.declareLegacyNamespace();

const yext = goog.require('yext');

/**
 * @constructor
 */
const FindLoggedIn = function(FB) {
  this.matchLoggedIn = $('.js-match-logged-in').val() == 'true';
  this.shortUrlCode = $('.js-short-url-code').val();
  this.invitationId = $('.js-invitation-id').val();
  this.finishedFb = false;
  this.finishedGoogle = false;
  this.loggedInPartnerIds = [];
  this.FB = FB;
  /**
   * @type {!jQuery}
   */
  this.$ajaxFailureLink = $('.js-ajax-failure-link');
  this.init();
};

/**
 * Initializes the page
 */
FindLoggedIn.prototype.init = function() {
  this.allowedDomains = new Set(['sandbox.leavefeedback.app', 'qa.leavefeedback.app', 'www.leavefeedback.app', 'leavefeedback.app']);
  this.findFacebookLogin();
  this.findGoogleLogin();
  this.initTimer();
};

/**
 * This bad boy is a modified copy of from yext.storm.router. I needed something that doesn't assume
 * the standard yext format of +/s/businessId + /path etc.
 */
FindLoggedIn.prototype.route = function(path) {
  return function(obj) {
    var pattern = path;
    obj = obj || {};
    var queryParams = [];
    for (var key in obj) {
      var vals = obj[key];
      if (!Array.isArray(obj[key])) {
        vals = [obj[key]];
      }
      for (var i = 0; i < vals.length; i++) {
        var val = encodeURIComponent(vals[i]);
        var pattern2 = pattern.replace(':'+key, val);
        if (pattern == pattern2) {
          queryParams.push(key + '=' + val);
        }
        pattern = pattern2;
      }
    }
    if (queryParams.length > 0) {
      pattern += '?' + queryParams.join('&');
    }
    return pattern;
  };
};

/**
 * Checks to see if all partner checks have been finished so we can send the data over
 */
FindLoggedIn.prototype.initTimer = function() {
  var self = this;
  var pollInterval = 50;
  var timeout = 1000; // In total, times out after 1 second
  var retries = 0;
  var retryLimit = timeout/pollInterval;
  var timer = setInterval(function() {
    if (!self.matchLoggedIn || (self.finishedFb && self.finishedGoogle) || retries > retryLimit) {
      clearInterval(timer);
      self.redirectToPath('/tp/:shortUrlCode/redirect');
    }
    retries++;
  }, pollInterval);
};

FindLoggedIn.prototype.findGoogleLogin = function() {
  var self = this;
  function check(sitename, imgurl) {
    var img = new Image();
    img.onload = function() {
      self.loggedInPartnerIds.push(715); // TAGS_GOOGLEMYBUSINESS_ID
      self.finishedGoogle = true;
    };
    img.onerror = function() {
      self.finishedGoogle = true;
    };
    img.src = imgurl;
  }
  var googleImageUrl = 'https://accounts.google.com/CheckCookie?continue=https://www.google.com/intl/en/images/logos/accounts_logo.png';
  check('Google', googleImageUrl);
};

FindLoggedIn.prototype.findFacebookLogin = function() {
  var self = this;
  console.log('FB: ' + !!this.FB);
  if (this.FB) {
    let initResponse = this.FB.init({
      // Uncomment this for local testing.
      // appId: '290761951050707',
      appId: '367958046890909',
      status: true,
      version: 'v13.0',
    });
    console.log(initResponse);
    this.FB.Event.subscribe('auth.statusChange', function(event) {
      console.log('Got event!');
      console.log(event);
    });
    this.FB.getLoginStatus(function(response) {
      console.log('FB login status: ' + response.status);
      if ((response.status === 'connected')
        || (response.status === 'not_authorized')) {
        self.loggedInPartnerIds.push(559); // TAGS_FACEBOOK_ID
      }
      self.finishedFb = true;
    });
  } else {
    self.finishedFb = true;
  }
};

/**
 * Redirect the user to the selected path.
 */
FindLoggedIn.prototype.redirectToPath = function(shortPath) {
  // we want to match the current subdomain to prevent CORS issues and ensure we hit the right env
  let host = window.location.hostname;
  var pathBase = '';
  if (host === 'localhost') {
    pathBase = 'http://localhost';
  } else if (this.allowedDomains.has(host)) {
    pathBase = 'https://' + host;
  } else {
    pathBase = 'https://www.leavefeedback.app';
  }
  var path = pathBase + shortPath;
  var getUrl = this.route(path);
  if (!this.matchLoggedIn) {
    this.loggedInPartnerIds = null;
  }
  var url = getUrl({
    'shortUrlCode': this.shortUrlCode,
    'loggedInPartnerIds': this.loggedInPartnerIds,
    'invitationId': this.invitationId,
  });
  var self = this;
  $.get(url, function(data) {
    window.location = data['surveyUrl'];
  }).fail(function() {
    self.$ajaxFailureLink.show();
  });
};

goog.exportSymbol('yext.surveyserving.FindLoggedIn', FindLoggedIn);

exports = FindLoggedIn;
