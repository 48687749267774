goog.module('yext.tracing.span');

const {now} = goog.require('yext.tracing.utils');

class Log {
  /**
   * Log constructor.
   *
   * @param {!Object.<string, Any>} fields Key value pairs
   * @param {number} [timestamp = now()] The log timestamp.
   */
  constructor(fields, timestamp = now()) {
    this['fields'] = fields;
    this['timestamp'] = timestamp;
  }
}

/**
 * @typedef {!Object} params
 * @property {string} name The span's name.
 * @property {Context} context The span's context.
 * @property {Context} [parentContext = null] The span's parent context.
 * @property {number} [start = now()] The span's start time.
 */
var Params;

class Span {
  /**
   * @param {!Params} params
   */
  constructor({name, context, parentContext = null, start = now()}) {
    this['name'] = name;
    this['context'] = context;
    this['start'] = Math.round(start);
    this['end'] = null;
    this['tags'] = {};
    this['logs'] = [];
    // Note: this is only relevant for top level spans and
    // the context refers to tracing contexts from other processes.
    this['parentContext'] = parentContext;
    this['children'] = [];
  }

  /**
   * Sets the start timestamp for the current span.
   *
   * @param {number} [timestamp = now()] The start timestamp.
   */
  setStart(timestamp = now()) {
    this['start'] = Math.round(timestamp);
  }

  /**
   * Sets the end timestamp for the current span.
   *
   * @param {number} [timestamp = now()] The end timestamp.
   */
  finish(timestamp = now()) {
    this['end'] = Math.round(timestamp);
  }

  /**
   * Determines if the span has been closed.
   *
   * @return {boolean}
   */
  isComplete() {
    return this['end'] !== null;
  }

  /**
   * Adds a single tag to the span.
   *
   * @param {string} key
   * @param {Any} value
   * @return {Span} The current span instance.
   */
  addTag(key, value) {
    this.addTags({[key]: value});
    return this;
  }

  /**
   * Adds the tags defined by the key value map to the span.
   *
   * @param {!Object.<string, Any>} keyValueMap Tags to add.
   * @return {Span} The current span instance.
   */
  addTags(keyValueMap) {
    for (const key in keyValueMap) {
      if (keyValueMap.hasOwnProperty(key)) {
        this['tags'][key] = keyValueMap[key];
      }
    }
    return this;
  }

  /**
   * Adds a log to the current span.
   *
   * @param {!Object.<string, Any>} fields
   * @param {number} [timestamp = now()] The log timestamp.
   * @return {Span} The current span instance.
   */
  log(fields, timestamp = now()) {
    this['logs'].push(new Log(fields, timestamp));
    return this;
  }
}

exports = Span;
