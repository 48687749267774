/**
 * @fileoverview Survey Page - handles form validation and page setup
 *
 * @author Samantha Landen (slanden@yext.com)
 */
goog.module('yext.surveyserving.Survey');
goog.module.declareLegacyNamespace();

const Field = goog.require('yext.Field');
const Model = goog.require('yext.mvc.Model');
const REVIEW_RATE_EXCEEDED_STATUS_CODE = 429;
/** @suppress {extraRequire} */
goog.require('yext.plugins.inputchange');
const ContentValidator = goog.require('yext.surveyserving.ContentValidator');

/**
 * @constructor
 */
const Survey = function() {
  /**
   * @type {!jQuery}
   */
  this.$form = $('.js-survey');

  /**
   * @type {!jQuery}
   */
  this.$submitMsg = $('.js-submitting-msg');

  /**
   * @type {Field}
   */
  this.field = new Field(this.$form);

  /**
   * @type {Model}
   * @private
   */
  this._model = new Model();

  /**
   * @type {ContentValidator}
   * @private
   */
  this._contentValidator = new ContentValidator();

  this.init();
};

Survey.INPUT_PREFIX = 'surveyFormView.';

/**
 * Initializes the page
 */
Survey.prototype.init = function() {
  this.initModel();
  this.bindModelChanges();
  this.bindForm();
  this.bindStarHover();
  this.renderCurrentScore();
};

/**
 * It's possible if you select a form element for a score before the
 * javascript finishes loading, it will fail to display properly. The input is now "checked", but
 * we did not detect the model change and further clicks will not result
 * in a form input change to trigger the proper render without trying to change your score.
 *
 * This function is run at the end of javascript init to make sure we render things properly.
 */
Survey.prototype.renderCurrentScore = function() {
  var scores = this.$form.find('.js-score');
  var self = this;
  scores.each(function() {
    var $this = $(this);
    if ($this.is(':checked')) {
      $this.trigger('change');
    }
  });
};
/**
 * Initialize the model
 */
Survey.prototype.initModel = function() {
  this._model.init({
    'score': null,
    'message': null,
    'author': this.$form.find('.js-author-field').find('.js-survey-field').val(),
    'email': this.$form.find('.js-email-field').find('.js-survey-field').val(),
    'pendingInvitationId': this.$form.find('.js-pending-invitation-id').find('.js-survey-field').val(),
    'disclaimer': this.$form.find('.js-disclaimer').is(':checked'),
    'noCaptcha': this.$form.data('no-captcha'),
  });
};

/**
 * Binds the model update when the inputs change
 */
Survey.prototype.bindModelChanges = function() {
  var self = this;
  this.$form.find('.js-survey-field').inputchange(function() {
    var $this = $(this);
    var prop = $this.attr('name').substring(Survey.INPUT_PREFIX.length);
    self._model.set(prop, $this.val());
    if ($this.hasClass('js-score')) {
      self.renderStars($this.val());
    }
  });

  this.$form.find('.js-disclaimer').inputchange(function() {
    self._model.set('disclaimer', $(this).prop('checked'));
  });
};

/**
 * Bind the form submit
 */
Survey.prototype.bindForm = function() {
  var self = this;
  this.field.setValidator(function() {
    var errors = self._contentValidator.validate(self._model);
    self.render(errors);
    return !errors.length;
  });

  this.$form.on('beforeSubmit.field', function() {
    self.$submitMsg.show();
  });

  this.$form.on('error.field', function(ev, resp) {
    self.$submitMsg.hide();
    if (resp['status'] === REVIEW_RATE_EXCEEDED_STATUS_CODE) {
      self.render(['duplicate']);
    } else {
      self.render(['generic']);
    }
    // Captcha is only present if there is no invitation id present
    if (!self._model.get('pendingInvitationId') && !self._model.get('editSurvey')) {
      grecaptcha.reset();
    }
  });

  this.$form.on('save.field', function(ev, resp) {
    if (resp['success']) {
      window.location.href = resp['url'];
    } else {
      // Captcha is only present if there is no invitation id present
      if (!self._model.get('pendingInvitationId') && !self._model.get('editSurvey')) {
        grecaptcha.reset();
      }
      self.$submitMsg.hide();
      var fieldErrors = [];
      if (resp['errors']) {
        resp['errors'].forEach(function(error) {
          fieldErrors.push(error['field']);
        });
      } else if (resp['captchaInvalid']) {
        fieldErrors.push('captcha-validation');
      } else if (resp['emailInvalid']) {
        fieldErrors.push('email-validation');
      } else {
        fieldErrors.push('generic');
      }
      self.render(fieldErrors);
    }
  });
};

/**
 * Binds filling in the stars on hover
 */
Survey.prototype.bindStarHover = function() {
  var self = this;
  $('.js-star-container').on('mouseover', function() {
    self.renderStars($(this).find('.js-score').val());
  }).on('mouseout', function() {
    self.renderStars(self._model.get('score'));
  });
};

/**
 * Updates the UI with the given errors
 *
 * @param {!Array.<string>} errors
 */
Survey.prototype.render = function(errors) {
  var self = this;
  this.$form.find('.js-error').hide();
  errors.forEach(function(error) {
    var $errorField = self.$form.find('.js-' + error + '-field');
    if ($errorField.length == 0) {
      $errorField = $('.js-generic-field');
    }
    $errorField.find('.js-error').show();
  });
};

/**
 * Changes the number of stars that are filled in to the given value
 *
 * @param {number} selected
 */
Survey.prototype.renderStars = function(selected) {
  $('.js-star').removeClass('star-selected').each(function() {
    if ($(this).siblings('.js-survey-field').val() <= selected) {
      $(this).addClass('star-selected');
    }
  });
};

Survey.prototype.getModel = function() {
  return this._model;
};

goog.exportSymbol('yext.surveyserving.Survey', Survey);

exports = Survey;
