goog.module('yext.tracing.tracer');

const Span = goog.require('yext.tracing.span');
const Context = goog.require('yext.tracing.context');
const {now, id, uuid} = goog.require('yext.tracing.utils');

/**
 * Defined a tracing object that implicitly records various
 * timing metrics relating to page navigation.
 *
 * Note that this module should be initialized within a DOM ready handler.
 */
class Tracer {
  constructor() {
    this._currentRootSpan = null;
    this['spans'] = [];
  }

  /**
   * Creates a root span that has no parent span id.
   *
   * @param {string} name The span's name.
   * @param {number} [start = now()] The span's start timestamp.
   * @param {Context} [parentContext = null] The parent context is applicable.
   * @return {Span} A new parent span.
   */
  startRootSpan(name, start = now(), parentContext = null) {
    if (this._currentRootSpan !== null && !this._currentRootSpan.isComplete()) {
      throw new Error('a root span already exists, create a child span instead');
    }

    const context = new Context({
      traceId: id(),
      spanId: id(),
      correlationId: uuid(),
    });

    const root = new Span({name, context, parentContext, start});
    this['spans'].push(root);
    this._currentRootSpan = root;

    return root;
  }

  /**
   * Creates a child span with the current root span as its parent.
   *
   * @param {string} name The span's name.
   * @param {Span} [parent] The parent span - defaults to the root span.
   * @param {number} [start] The span's start timestamp - defaults to now.
   * @return {Span} A new child span.
   */
  startChildSpan(name, parent = this._currentRootSpan, start = now()) {
    if (parent === null) {
      throw new Error('cannot start child span without a parent');
    } else if (parent.isComplete()) {
      throw new Error('parent span has already completed');
    }

    const context = new Context({
      traceId: parent.context.traceId,
      spanId: id(),
      correlationId: parent.context.correlationId,
    });

    const parentContext = parent.context;
    const child = new Span({name, context, parentContext, start});
    parent.children.push(child);

    return child;
  }

  /**
   * Adds the given span to this tracer.
   *
   * @param {Span} span The span to add.
   */
  addSpan(span) {
    this['spans'].push(span);
  }
}

exports = Tracer;
