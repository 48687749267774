goog.module('yext.tracing');
goog.module.declareLegacyNamespace();

const {now, postData, id, uuid} = goog.require('yext.tracing.utils');
const {calculateTimingMetrics} = goog.require('yext.tracing.metrics');

const Span = goog.require('yext.tracing.span');
const Context = goog.require('yext.tracing.context');
const Tracer = goog.require('yext.tracing.tracer');
const NoopTracer = goog.require('yext.tracing.nooptracer');

const BEACON_URL = '/metrics/collect';

/**
 * Retrieves the global tracer object.
 *
 * @return {Tracer | NoopTracer} A tracer.
 */
function getTracer() {
  return window['tracer'] ? window['tracer'] : new NoopTracer();
}

/**
 * Retrieves the global page span.
 *
 * @return {Span} The page span.
 */
function getPageSpan() {
  return window['pageSpan'] ? window['pageSpan'] : new Span({name: 'placeholder-page-span'});
}

/**
 * Initializes a global tracer object. Note that this must
 * be called inside a document ready handler.
 *
 * @return {Tracer} The initialized tracer.
 */
function init(props) {
  const applicationName = props['applicationName'];
  const businessId = props['businessId'];
  const userId = props['userId'];
  const employeeId = props['employeeId'];
  const runMode = props['runMode'] || 'TEST';
  const context = props['context'];

  _log('initialized tracing module with context:', context);

  window['tracer'] = new Tracer();
  const _initializedTime = now();
  const _parentContext = context !== null ? Context.fromLightstepTextMap(context) : null;
  window['pageSpan'] = new Span({
    name: applicationName,
    parentContext: _parentContext,
    start: null, // will be set on page unload
    context: new Context({
      spanId: id(),
      traceId: _parentContext ? _parentContext['traceId'] : id(),
      correlationId: _parentContext ? _parentContext['correlationId'] : uuid(),
    }),
  });

  /**
   * Determines if the current run mode is 'TEST'.
   *
   * @return {boolean}
   */
  function _isTest() {
    return runMode === 'TEST';
  }

  /**
   * Logs the given msg if the current run mode is 'TEST'.
   */
  function _log(...args) {
    _isTest() && console.debug.apply(null, args);
  }

  /**
   * Sends tracing information collected throughout
   * the duration of the current page.
   */
  function _sendMetricsBeacon() {
    const spans = [window['pageSpan']].concat(window['tracer']['spans']);
    const data = {
      'applicationName': applicationName,
      'businessId': businessId,
      'userId': userId,
      'employeeId': employeeId,
      'url': window.location.href,
      'userAgent': navigator.userAgent,
      'spans': spans,
      'navigationMetrics': calculateTimingMetrics(),
    };
    if (navigator.sendBeacon) {
      _log('sending beacon with sendBeacon', data);
      const sent = navigator.sendBeacon(BEACON_URL, JSON.stringify(data));
      if (!sent) {
        _log('sendBeacon failed, falling back to xhr');
        postData(BEACON_URL, data);
      }
    } else {
      postData(BEACON_URL, data);
    }
  }

  /**
   * Completes the global page span and populates it with
   * timing metrics regarding various load events.
   */
  function _completePageSpan() {
    let start = 0;
    let end = _initializedTime;

    if (window.performance) {
      if (window.PerformanceNavigationTiming) {
        const timeOrigin = window.performance['timeOrigin'];
        const [navigationTiming] = window.performance.getEntriesByType('navigation');
        // responseStart and loadEventEnd are offsets from timeOrigin in ms
        start = (timeOrigin + navigationTiming.responseStart) * 1000;
        end = (timeOrigin + navigationTiming.loadEventEnd) * 1000;
      } else if (window.PerformanceTiming) {
        const perfTiming = window.performance['timing'];
        // responseStart and loadEventEnd are UNIX timestamp in ms
        start = perfTiming['responseStart'] * 1000;
        end = perfTiming['loadEventEnd '] * 1000;
      }
    }

    const pageSpan = window['pageSpan'];
    pageSpan.setStart(start);
    pageSpan.addTags(calculateTimingMetrics());
    pageSpan.finish(end);
    if (pageSpan.parentContext && pageSpan.parentContext['correlationId']) {
      pageSpan.addTag('guid:cid', pageSpan.parentContext['correlationId']);
    }
  }

  window.addEventListener('unload', () => {
    _completePageSpan();
    if (_isTest()) {
      console.debug('collected spans:', [window['pageSpan']].concat(window['tracer']['spans']));
    } else {
      _sendMetricsBeacon();
    }
  });

  return window['tracer'];
}

exports = {init, getTracer, getPageSpan};

goog.exportSymbol('yext.tracing.init', init);
goog.exportSymbol('yext.tracing.tracer', getTracer);
goog.exportSymbol('yext.tracing.pageSpan', getPageSpan);
