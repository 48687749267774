goog.module('yext.plugins.inputchange');
goog.module.declareLegacyNamespace();

/**
 * Cross browser "oninput" event handler to detect immediate changes to <input>
 * (instead of after focus is lost).
 *
 * Tested on IE7/8/9, Firefox 12, Chrome 18
 *
 * @author Jeff Grossman (jeff@yext.com)
 * @suppress {deprecated}
 */
(function() {
  var events = [
    'paste.inputchange',
    'keypress.inputchange',
    'keydown.inputchange',
    'change.inputchange',
    'input.inputchange',
    'propertychange.inputchange',
  ];

  var shouldHandleEvent = function(e) {
    if ($.inArray(e.type, ['keydown', 'keypress']) < 0 || !e.keyCode) {
      return true;
    }

    // For a keydown event, don't count any keys in $.ui.keyCode except for:
    var validKeys = [
      $.ui.keyCode.BACKSPACE,
      $.ui.keyCode.COMMA,
      $.ui.keyCode.DELETE,
      $.ui.keyCode.NUMPAD_ADD,
      $.ui.keyCode.NUMPAD_DECIMAL,
      $.ui.keyCode.NUMPAD_DIVIDE,
      $.ui.keyCode.NUMPAD_MULTIPLY,
      $.ui.keyCode.NUMPAD_SUBTRACT,
      $.ui.keyCode.PERIOD,
      $.ui.keyCode.SPACE,
    ];

    if ($.inArray(e.keyCode, validKeys) >= 0) {
      return true;
    }

    // Don't count any of the remaining $.ui.keyCode keys as causing a change
    var valid = true;
    $.each($.ui.keyCode, function(k, v) {
      if (e.keyCode == v) {
        valid = false;
        return false;
      }
    });

    return valid;
  };

  $.fn.inputchange = function(arg) {
    if (arg === 'destroy') {
      this.unbind('.inputchange');
      return this;
    }

    if (typeof arg !== 'function') {
      return this;
    }

    // Bind escape to return false to not bubble up to prevent an IE 7/8 bug
    // where propertychange would fire when hitting escape
    if ('onpropertychange' in document.documentElement) {
      this.keydown(function(e) {
        return e.keyCode != $.ui.keyCode.ESCAPE;
      });
    }

    return this.bind(events.join(' '), function(e) {
      if (shouldHandleEvent(e)) {
        $.proxy(arg, this)(e);
      }
    });
  };
})();

