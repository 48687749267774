goog.module('yext.tracing.nooptracer');

const {now} = goog.require('yext.tracing.utils');

/**
 * A no-op tracer with the same interface as Tracer.
 */
class NoopTracer {
  constructor() {
    this['spans'] = [];
  }

  startRootSpan(name, startTime) {
    return new NoopSpan(name, null, startTime);
  }
  startChildSpan(name, startTime) {
    return new NoopSpan(name, null, startTime);
  }
}

/**
 * @typedef {!Object} params
 * @property {string} name The span's name.
 * @property {Context} [parentContext = null] The span's context.
 * @property {number} [start = now()] The span's start time.
 */
var Params;

class NoopSpan {
  /**
   * @param {!Params} params
   */
  constructor({name, parentContext = null, start = now()}) {
    this['name'] = name;
    this['start'] = Math.round(start);
    this['context'] = {
      getHeaderMap: () => {},
    };
  }

  finish() {
    this['finish'] = Math.round(now());
  }
}

exports = NoopTracer;
