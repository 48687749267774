goog.module('yext.tracing.context');

const LS_PREFIX_BAGGAGE = 'ot-baggage-';

class Context {
  /**
   *
   * @param {string} traceId The trace id.
   * @param {string} spanId The current span id.
   * @param {string} [correlationId] The correlation id.
   * @param {Map.<string, Any>} baggageItems
   */
  constructor({
    traceId = null,
    spanId = null,
    correlationId = null,
    baggageItems = {},
  }) {
    this['traceId'] = traceId;
    this['spanId'] = spanId;
    this['correlationId'] = correlationId;
    this['baggageItems'] = baggageItems;
  }

  /**
   * Adds a baggage item to the current context.
   *
   * @param {string} key They baggage item key.
   * @param {Any} value The baggage item value.
   */
  addBaggage(key, value) {
    this.baggageItems[key] = value;
  }

  /**
   * Returns context's header information
   *
   * @return {Map.<string, String>} The constructed header information.
   */
  getHeaderMap() {
    return {
      'correlation-id': this['correlationId'],
      'ot-tracer-traceid': this['traceId'],
      'ot-tracer-spanid': this['spanId'],
    };
  }

  /**
   * Constructs a context a lightstep text map.
   *
   * @param {Map.<string, Any>} textMap A lightstep text map.
   * @return {Context} The constructed context.
   */
  static fromLightstepTextMap(textMap) {
    const spanId = textMap['ot-tracer-spanid'];
    const traceId = textMap['ot-tracer-traceid'];
    const correlationId = textMap['correlation-id'];
    const baggageItems = {};

    if (!spanId && !traceId) {
      return null;
    }

    for (const key in textMap) {
      if (textMap.hasOwnProperty(key) && key.startsWith(LS_PREFIX_BAGGAGE)) {
        const baggageKey = key.slice(LS_PREFIX_BAGGAGE.length);
        baggageItems[baggageKey] = textMap[key];
      }
    }

    return new Context({
      traceId,
      spanId,
      correlationId,
      baggageItems,
    });
  }
}

exports = Context;
