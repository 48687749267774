/**
 * Wraps a form that will be submitted via ajax. Also stores the forms inital
 * values so they could be restored. Uses publish/subscribe pattern for form
 * submit events. Also handles file uploads using the fine upload plugin
 *
 * @author matt@yext.com
 */
goog.provide('yext.Field');

goog.require('yext');
goog.require('yext.dialog');
goog.require('yext.fileupload');
goog.require('yext.tracing.ajax');

goog.scope(function() {
  var defaults_ = {
    onSaveProgressDialog: false, // Server can take long to save our form. Use `true` to show a dialog
  };

  /**
   * @constructor
   */
  yext.Field = function($form, opts) {
    this.opts = $.extend(defaults_, opts);

    this.$form = $form;
    this.$submitButton = this.$form.find('[type=submit]');
    this.action = this.opts['action'] || $form.attr('action') || '';
    this.method = $form.attr('method') || 'GET';

    this.additionalData = [];
    this.files = [];
    this.fileInputs = [];
    this._internalData = [];
    this._preventDefault = false;

    this._isValid = function() {
      return true;
    };

    // This stores values using jQuery serializeArray (name/value)
    this.initialValues = this.formValues();

    this.bindSaveProgressDialog();
    this.bindSubmit();
    this.bindReset();
  };
  goog.exportSymbol('yext.Field', yext.Field);

  /**
   * Submit the form via ajax
   */
  yext.Field.prototype.submit = function() {
    var self = this;
    var data;
    // Allow the form to be sent as a JSON string or as
    // a serialized string. Attach additional data in both cases.
    var settings = {};
    if (this.$form.data('json') === true) {
      data = this.formToObject(this.$form);

      if (this.additionalData.length > 0) {
        data.additionalData = this.additionalData;
      }
      if (this._internalData.length > 0) {
        data.additionalData = $.extend(data.additionalData, this._internalData);
      }

      settings = {
        contentType: 'application/json',
        data: yext.JSON.stringify(data),
      };
    } else {
      if (window['isLegacyLocationProfile']) {
        data = this.getData();
      } else {
        data = yext.Field.trimExtraData(this.getData());
      }
    }

    const params = Object.assign(
      {},
      {
        url: self.action,
        data: data,
        type: self.method,
      },
      settings,
    );

    let operation;
    const currentUrl = window.location.href || '';
    if (currentUrl.includes('/entity/edit')) {
      operation = 'EntityManager.edit.client';
    } else if (currentUrl.includes('assets/edit/')) {
      operation = 'AssetBase.editSaveVariantContent.client';
    } else if (currentUrl.includes('/approvals/edit/')) {
      operation = 'ContentApprovals.saveEntityEditContent.client';
    } else {
      operation = 'UnknownController.client';
    }

    // Extend the default settings with the options above (if json)
    yext.tracing.ajax.ajax(operation, params)
      .then(function(response) {
        if (response.xhr.getResponseHeader('content-type').indexOf('json') === -1) {
          response = yext.fileupload.parseHtmlEncodedJson(response.response);
        }
        self.handleResponse(response.response);
        self.$form.trigger('always.field');
        self.reset();
      })
      .catch(function(status) {
        self.$form.trigger('error.field', {
          status: status,
        });
        self.$form.trigger('always.field');
        self.reset();
      });
  };

  /**
   * Deals with handling the save response
   *
   * @param {!Object} response The save field response
   */
  yext.Field.prototype.handleResponse = function(response) {
    if (response['actions']) {
      this.$form.trigger('actions.field', response);
    } else {
      this.$form.trigger('saveBeforeSetIntialValues.field', response);
      this.initialValues = this.formValues();
      this.$form.trigger('save.field', response);
    }
  };

  /**
   * Get form data as name/value pair
   *
   * @return {!Array}
   */
  yext.Field.prototype.getData = function() {
    var data = this.$form.serializeArray();
    data = data.concat(this.additionalData);
    data = data.concat(this._internalData);
    return data;
  };

  /**
   * Trim extra data from a given array. Currently, this function removes extra elements with
   * the name "field". This is primarily required for submitting struct fields, and is a no-op
   * on other field types.
   */
  yext.Field.trimExtraData = function(data) {
    let newData = [];
    let fieldSeen = false;
    data.forEach(function(element, index) {
      if (element.name === 'field') {
        if (fieldSeen) {
          return;
        } else {
          fieldSeen = true;
        }
      }
      newData.push(data[index]);
    });
    return newData;
  };

  /**
   * bindSaveProgressDialog will optionally hook into the form submission, and show a delayed
   * progress dialog based on how fast/slow the server response is.
   */
  yext.Field.prototype.bindSaveProgressDialog = function() {
    var dialog = undefined;
    if (this.opts.onSaveProgressDialog === false) {
      return;
    }

    this.$form.on('beforeSubmit.field', function() {
      dialog = yext.dialog.showDelayedProgressDialog(yext.msg('Saving...'));
    });

    /**
     * Since the field object is abstracted away from the view,
     * I chose to bind to all handlers because the save and error events are fired
     * before the always event (in which case, whoever is using the field class
     * could have destroyed the original `field form` based on the mechanism
     * they use to render the view [e.g. html replacement vs jquery template parsing]).
     */
    this.$form.on('save.field error.field always.field', function() {
      yext.dialog.hideDelayedProgressDialog(dialog);
    });
  };

  /**
   * formToObject will convert a form to a javascript object, ignoring buttons and duplicate radio inputs
   * TODO(billy) this should probably just be a core helper method
   * @param $form {!jQuery} The form extract data from.
   * @return {!Object} The resulting object of data representing the form
   */
  yext.Field.prototype.formToObject = function($form) {
    var obj = {};
    $form.find('input[type="text"],input[type="hidden"],input[type="radio"]:checked,textarea,select').each(function() {
      var $el = $(this);
      if ($el.attr('type') === 'submit') {
        return true;
      }

      obj[$el.attr('name')] = $el.val();
    });
    return obj;
  };

  /**
   * Take a serialized string and convert it to a javascript object
   * e.g. fieldName=visitRetap&actionId=3&rawText=sdfsf
   *      pattern (field=val)
   */
  yext.Field.prototype.convertToObject = function(s) {
    var obj = {};

    var fields = s.split('&');
    for (var i = 0; i < fields.length; i ++) {
      var fv = fields[i].split('=');

      // Sometimes the value of a number is interpretted as a string
      // so we have to check to be sure, and cast it to a number if necessary
      if (isNaN(fv[1]) !== true) {
        obj[fv[0]] = parseInt(fv[1]);
      } else {
        obj[fv[0]] = fv[1];
      }
    }

    return obj;
  };

  /**
   * Submit the form via ajax with files
   *
   * This requires fileInputs as well as files for compatibility with IE
   */
  yext.Field.prototype.submitWithFiles = function() {
    var self = this;
    var data = this.getData();

    if (!window['isLegacyLocationProfile']) {
      data = yext.Field.trimExtraData(data);
    }

    yext.fileupload.upload({
      files: this.files,
      fileInputs: this.fileInputs,
      action: this.action,
      data: data,
      done: function(response) {
        self.handleResponse(response);
      },
      error: function(response) {
        self.$form.trigger('error.field', response);
      },
      always: function() {
        self.$form.trigger('always.field');
        self.reset();
      },
    });
  };

  /**
   * Bind the submit method to the form submit
   */
  yext.Field.prototype.bindSubmit = function() {
    var self = this;

    this.$form.submit(function(e) {
      e.preventDefault();

      // Validate before saving
      if (self.isValid()) {
        if (self._internalData.length) {
          self.$form.trigger('beforeSubmit.field', [undefined, undefined, undefined, self._internalData]);
        } else {
          self.$form.trigger('beforeSubmit.field');
        }

        // Allow the submit be conditionally prevented
        if (self._preventDefault) {
          self._preventDefault = false;
          self.$form.trigger('always.field');
          return;
        }

        if (self.files.length) {
          self.submitWithFiles();
        } else {
          self.submit();
        }
      }
    });
  };

  yext.Field.prototype.bindReset = function() {
    var that = this;
    this.$form.find('.js-reset-field').on('click', function(e) {
      e.stopPropagation();

      that.$form.trigger('reset');
    });
  };

  /**
   * Add files to be uploaded with the request
   *
   * @param {!Object} file File object to be uploaded with form
   */
  yext.Field.prototype.addFile = function(file, fileInput) {
    this.files.push(file);
    this.fileInputs.push(fileInput);
  };

  /**
   * Returnes a boolean - the return value of the validator function for this function.
   *
   * @return {boolean} the return value of the validation function
   */
  yext.Field.prototype.isValid = function() {
    var result = this._isValid(this.$form);

    if (result !== true) {
      this.$form.trigger('validationError.field', result);
      result = false;
    }

    return result;
  };

  /**
   * Sets a function for the field which will be used to validate if save button should be enabled.
   *
   * param {function()} fn the validator function
   */
  yext.Field.prototype.setValidator = function(fn) {
    this._isValid = fn;
  };

  /**
   * Enable the submit button in the form
   */
  yext.Field.prototype.enableSubmit = function() {
    this.$submitButton.removeAttr('disabled');
  };

  /**
   * Enable the submit button if the validator passes
   */
  yext.Field.prototype.enableSubmitIfValid = function() {
    this.isValid(this.$form) && this.enableSubmit();
  };

  /**
   * Disable the submit button in the form
   */
  yext.Field.prototype.disableSubmit = function() {
    this.$submitButton.attr('disabled', 'disabled');
  };

  /**
   * Checks if the form is enableds
   */
  yext.Field.prototype.isDisabled = function() {
    return this.$submitButton.prop('disabled');
  };

  /**
   * Provides a prevent default like behavior right after beforeSubmit
   * event is fired
   */
  yext.Field.prototype.preventDefault = function() {
    this._preventDefault = true;
  };

  /**
   * Get the forms values as an array
   *
   * @return {array} Forms current values
   */
  yext.Field.prototype.formValues = function() {
    return this.$form.serializeArray();
  };

  /**
  * Returns the field to it's initial state
  */
  yext.Field.prototype.reset = function() {
    this.files = [];
    this.fileInputs = [];
    this.additionalData = [];
    this._internalData = [];
  };
});
