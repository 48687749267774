goog.module('yext.tracing.utils');

/**
 * Generates a random 8 byte (64 bits) hex string.
 *
 * @return {string} The generated hex string.
 */
exports.id = function() {
  return _hex(_rand(32), 8) + _hex(_rand(32), 8);
};

/**
 * Returns an unsigned x-bit random integer.
 *
 * @param {number} x Unsigned integer ranging from 0 to 53, inclusive.
 * @return {number} Unsigned x-bit random integer (0 <= f(x) < 2^x).
 */
function _rand(x) {
  if (x < 0 || x > 53) {
    return NaN;
  }
  const n = 0 | Math.random() * 0x40000000; // 1 << 30
  return x > 30 ? n + (0 | Math.random() * (1 << x - 30)) * 0x40000000 : n >>> 30 - x;
}

/**
 * Converts an integer to a zero-filled hexadecimal string.
 *
 * @param {number} num
 * @param {number} length
 * @return {string}
 */
function _hex(num, length) {
  let str = num.toString(16);
  let z = '0';
  for (let i = length - str.length; i > 0; i >>>= 1, z += z) {
    if (i & 1) {
      str = z + str;
    }
  }
  return str;
}

/**
 * Generates a random uuid string.
 *
 * @return {string} The generated uuid.
 */
exports.uuid = function() {
  return _hex(_rand(32), 8) + // time_low
        '-' +
        _hex(_rand(16), 4) + // time_mid
        '-' +
        _hex(0x4000 | _rand(12), 4) + // time_hi_and_version
        '-' +
        _hex(0x8000 | _rand(14), 4) + // clock_seq_hi_and_reserved clock_seq_low
        '-' +
        _hex(_rand(48), 12); // node
};

/**
 * Determines the current timestamp as the number of microseconds since epoch.
 *
 * @return {number} Microseconds since epoch.
 */
exports.now = function() {
  // use hi res timer if available
  if (window.performance && window.performance.now) {
    if (window.performance.timeOrigin) {
      const start = performance.timeOrigin;
      return (start + performance.now()) * 1000.0;
    } else if (window.performance.timing && window.performance.timing.navigationStart) {
      const start = performance.timing.navigationStart;
      return (start + performance.now()) * 1000.0;
    }
  }
  // fallback to low-res timer
  return Date.now() * 1000.0;
};

/**
 * Performs an xml http POST request with the given data and url.
 *
 * @param {string} url  The url to post to.
 * @param {Any} data The data to send.
 */
exports.postData = function(url, data) {
  const req = new XMLHttpRequest();
  req.open('POST', url);
  req.setRequestHeader('Content-Type', 'application/json');
  req.send(JSON.stringify(data));
};
