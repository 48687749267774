/**
 * @fileoverview Shows/hides progress, message or error dialogs.
 * @author Jeff Grossman (jeff@yext.com)
 */
goog.module('yext.dialog');
goog.module.declareLegacyNamespace();

const yext = goog.require('yext');

/**
 * Display a modal dialog with a message.  The dialog can't be closed by the
 * user (close on escape is disabled and the title bar with the X is hidden).
 *
 * @param {string} message
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showProgressDialog(message) {
  if (!message || message.length == 0) {
    message = yext.msg('Loading');
  }
  $('#progress-dialog .message').text(message);
  $('#progress-dialog').dialog({
    closeOnEscape: false,
    draggable: false,
    modal: true,
    resizable: false,
    autofocus: false,
  });
  // Hide title to remove close button
  $('#progress-dialog').prev('.ui-dialog-titlebar').hide();
}
goog.exportSymbol('showProgressDialog', showProgressDialog);

/**
 * Hide the progress dialog.
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function hideProgressDialog() {
  $('#progress-dialog').dialog('instance') && $('#progress-dialog').dialog('close');
}
goog.exportSymbol('hideProgressDialog', hideProgressDialog);

/**
 * Display a modal progress dialog after a delay.
 *
 * @param {string} message
 * @param {number=} delay
 *
 * @return {!Object} timeoutId
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showDelayedProgressDialog(message, delay) {
  return window.setTimeout(function() {
    showProgressDialog(message);
  }, delay || 500);
}
goog.exportSymbol('showDelayedProgressDialog', showDelayedProgressDialog);

/**
 * Hide a delayed progress dialog or cancel its appearance if it has not yet
 * showed.
 *
 * @param {!Object} timeoutId
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function hideDelayedProgressDialog(timeoutId) {
  window.clearTimeout(timeoutId);
  if ($('#progress-dialog').css('display') != 'none') {
    hideProgressDialog();
  }
}
goog.exportSymbol('hideDelayedProgressDialog', hideDelayedProgressDialog);

/**
 * Display a modal dialog to confirm a delete.
 *
 * @param {function()} onSubmit the function to call when the delete button is clicked
 * @param {function()=} onCancel the function to call when the cancel button is clicked
 * @param {string=} message
 * @param {string=} title of the dialog window
 * @param {string=} deleteButtonText that appears on delete button
 * @param {!Object=} width in pixels of the dialog window
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showDeleteDialog(
  onSubmit,
  onCancel,
  message,
  title,
  deleteButtonText,
  width) {
  // Enable developers to pass in an object literal
  // of all the properties, instead of a really gross signature
  // e.g. support use case:
  //    showDeleteDialog({
  //      onSubmit: function() { ... }
  //      ...
  //    });
  if (yext.isObject(onSubmit)) {
    onCancel = onSubmit['onCancel'];
    message = onSubmit['message'];
    title = onSubmit['title'];
    deleteButtonText = onSubmit['deleteButtonText'];
    width = onSubmit['width'];
    onSubmit = onSubmit['onSubmit']; // Do this last, to avoid overwriting all the other props
  }

  // Default values
  title = title || yext.msg('Are you sure you want to delete this?');
  width = width || 'auto';
  deleteButtonText = deleteButtonText || yext.msg('Delete');

  showSubmitDialog(
    onSubmit,
    onCancel,
    message,
    title,
    deleteButtonText,
    'button-red',
    null,
    null,
    width);
}
goog.exportSymbol('showDeleteDialog', showDeleteDialog);

/**
 * Display a modal dialog to confirm a submit.
 *
 * @param {function()} onSubmit the function to call when the submit button is clicked
 * @param {function()=} onCancel the function to call when the cancel button is clicked
 * @param {string=} message
 * @param {string=} title of the dialog window
 * @param {string=} submitButtonText that appears on submit button
 * @param {string=} submitButtonStyle that styles submit button
 * @param {string=} cancelButtonText that appears on cancel button
 * @param {string=} cancelButtonStyle that styles cancel button
 * @param {!Object=} width in pixels of the dialog window
 * @param {string=} dialogClass to put onto the dialog
 * @param {boolean=} dontHide dialog after button click
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showSubmitDialog(
  onSubmit,
  onCancel,
  message,
  title,
  submitButtonText,
  submitButtonStyle,
  cancelButtonText,
  cancelButtonStyle,
  width,
  dialogClass,
  dontHide) {
  // Enable developers to pass in an object literal
  // of all the properties, instead of a really gross signature
  // e.g. support use case:
  //    showSubmitDialog({
  //      onSubmit: function() { ... }
  //      ...
  //    });
  if (yext.isObject(onSubmit)) {
    onCancel = onSubmit['onCancel'];
    message = onSubmit['message'];
    title = onSubmit['title'];
    submitButtonText = onSubmit['submitButtonText'];
    submitButtonStyle = onSubmit['submitButtonStyle'];
    cancelButtonText = onSubmit['cancelButtonText'];
    cancelButtonStyle = onSubmit['cancelButtonStyle'];
    width = onSubmit['width'];
    dialogClass = onSubmit['dialogClass'];
    dontHide = onSubmit['dontHide'];
    onSubmit = onSubmit['onSubmit']; // Do this last, to avoid overwriting all the other props
  }

  // Default values
  submitButtonText = submitButtonText || yext.msg('Submit');
  submitButtonStyle = submitButtonStyle || 'btn-orange';
  cancelButtonText = cancelButtonText || yext.msg('Cancel');

  var $submitDialog = $('#submit-dialog').dialog({
    dialogClass: dialogClass,
    draggable: false,
    modal: true,
    resizable: false,
    minHeight: 'auto',
    minWidth: width,
    title: title,
  });

  $submitDialog.find('.message').html(message);
  $submitDialog.find('.primary span').html(submitButtonText);
  $submitDialog.find('.primary').addClass(submitButtonStyle);
  $submitDialog.find('.button-cancel').html(cancelButtonText);
  $submitDialog.find('.cancel').addClass(cancelButtonStyle);
  // Setup click event listeners
  $submitDialog.find('.primary,.cancel').off('click.dialog').on('click.dialog', function() {
    var ret;
    if ($(this).hasClass('primary')) {
      ret = onSubmit();
    } else if (yext.isFunction(onCancel)) {
      ret = onCancel();
    }
    if (!dontHide) {
      hideSubmitDialog();
    }
    return ret;
  });
  $submitDialog.find('.primary').focus();
}

/**
 * Hide the submit dialog
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function hideSubmitDialog() {
  $('#submit-dialog')
    .dialog('close')
    .find('.primary,.cancel')
    .off('click.dialog');
}

/**
 * Display a modal dialog with an error message.
 *
 * @param {string=} message
 * @param {string=} title of the dialog window
 * @param {!Object=} width in pixels of the dialog window
 * @param {function=} cb callback
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showErrorDialog(message, title, width, cb) {
  if (!message || message.length == 0) {
    message = yext.msg('Error');
  }
  if (!title || title.length == 0) {
    title = yext.msg('Error');
  }
  if (!width) {
    width = 'auto';
  }
  $('#error-dialog .message').html(message);
  var errorDialog = $('#error-dialog').dialog({
    draggable: false,
    modal: true,
    resizable: false,
    minHeight: 'auto',
    minWidth: width,
    title: title,
    autofocus: '.primary',
  });
  errorDialog.find('.primary').click(function() {
    errorDialog.dialog('close');
    return false;
  });
  errorDialog.find('.primary').focus();
}
goog.exportSymbol('showErrorDialog', showErrorDialog);

/**
 * Display a modal dialog with a simple message.
 *
 * @param {string} message
 * @param {string} title of the dialog window
 * @param {!Object} width in pixels of the dialog window
 * @param {function()=} cb Optional call back function when user clicks ok
 * @deprecated Use /ui/components/dialog/dialog instead.
 */
function showMessageDialog(message, title, width, cb, buttonOptions) {
  if (!message) {
    message = '';
  }
  if (!title) {
    title = '';
  }
  if (message.length == 0 && title.length == 0) {
    return;
  }
  if (!width) {
    width = 'auto';
  }
  $('#message-dialog .message').html(message);
  var messageDialog = $('#message-dialog').dialog({
    draggable: false,
    modal: true,
    resizable: false,
    title: title,
    minHeight: 'auto',
    minWidth: width,
    autofocus: '.primary',
  });

  if (buttonOptions) {
    if (buttonOptions['buttonClasses']) {
      $('#message-dialog .primary')
        .removeClass('button-blue')
        .addClass(buttonOptions['buttonClasses']);
    }
    if (buttonOptions['buttonText']) {
      $('#message-dialog .primary')
        .children('span')
        .text(buttonOptions['buttonText']);
    }
  }

  messageDialog.find('.primary').click(function() {
    messageDialog.dialog('close');

    if (yext.isFunction(cb)) {
      cb();
    }

    return false;
  });
  messageDialog.find('.primary').focus();
}
goog.exportSymbol('showMessageDialog', showMessageDialog);

exports = {
  showProgressDialog,
  hideProgressDialog,
  showDelayedProgressDialog,
  hideDelayedProgressDialog,
  showDeleteDialog,
  showSubmitDialog,
  hideSubmitDialog,
  showErrorDialog,
  showMessageDialog,
};
