goog.module('yext.tracing.ajax');
goog.module.declareLegacyNamespace();

const {getTracer} = goog.require('yext.tracing');
const {uuid} = goog.require('yext.tracing.utils');

/**
 * Performs a xhr operations using jQuery's ajax api.
 *
 * @param {string} operation The operation name.
 * @param {string} method The http request method.
 * @param {string} url The request url.
 * @param {Any} params The request params.
 * @return {Promise<Any>} The xhr request promise.
 */
function _jQueryAjax(operation, method, url, params) {
  const request = {
    method,
    url,
    'data': params,
  };

  return _customjQueryAjax(operation, request);
}

/**
 * Performs an xhr operation using jQuery's ajax api.
 *
 * @param {string} operation The operation name.
 * @param {!Object} request The complete ajax request. If present,
                           beforeSend and complete will be wrapped
                           to call tracing required functions as well.
 * @return {Promise<Any>} The xhr request promise.
 */
function _customjQueryAjax(operation, request) {
  const tracer = getTracer();
  const span = tracer.startRootSpan(operation);

  if (typeof $ === 'undefined') {
    throw new Error('jQuery is not defined');
  }
  const context = span.context;

  const beforeSend = xhr => {
    const headers = context.getHeaderMap();
    for (const key in headers) {
      xhr.setRequestHeader(key, headers[key]);
    }
    request.beforeSend && request.beforeSend(xhr);
  };

  const complete = () => {
    span.finish();
    request.complete && request.complete();
  };

  /* global $ */
  return new Promise((resolve, reject) => {
    const wrappedRequest = Object.assign(
      {},
      request,
      {
        beforeSend,
        complete,
        success: (response, status, xhr) => resolve({response, status, xhr}),
        error: xhr => reject(xhr.status),
      });
    $.ajax(wrappedRequest);
  });
}

/**
 * Performs a get xhr operation.
 *
 * @param {string} operation The operation name.
 * @param {string} url The request url.
 * @param {Any} params The request params.
 * @return {Promise<Any>} The xhr request promise.
 */
exports.get = function(operation, url, params) {
  return _jQueryAjax(operation, 'GET', url, params);
};

/**
 * Performs a post xhr operation.
 *
 * @param {string} operation The operation name.
 * @param {string} url The request url.
 * @param {Any} params The request params.
 * @return {Promise<Any>} The xhr request promise.
 */
exports.post = function(operation, url, params) {
  return _jQueryAjax(operation, 'POST', url, params);
};

/**
 * Performs an ajax xhr operation.
 *
 * @param {string} operation The operation name.
 * @param {!Object} request The complete ajax request.
 * @return {Promise<Any>} The xhr request promise.
 */
exports.ajax = function(operation, request) {
  return _customjQueryAjax(operation, request);
};
