/**
 * Arrays operation helpers.
 */
goog.module('yext.utils.arrays');
goog.module.declareLegacyNamespace();


/**
 * Compare two arrays by iterating through each of the elements and evaluating them
 * @param {!Array} ar1
 * @param {!Array} ar2
 * @return {boolean} true if they are both equal
 */
function equals(ar1, ar2) {
  // if the other array is a falsy value, return
  if (!Array.isArray(ar1) || !Array.isArray(ar2)) {
    return false;
  }

  // compare lengths - can save a lot of time
  if (ar1.length != ar2.length) {
    return false;
  }

  for (var i = 0; i < ar1.length; i++) {
    // Check if we have nested arrays, recurse into the arrays
    if (Array.isArray(ar1[i]) && Array.isArray(ar2[i])) {
      if (!equals(ar1[i], ar2[i])) {
        return false;
      }
    } else if (ar1[i] != ar2[i]) {
      // NOTE(billy): Two different object instances will never be equal: {x:20} != {x:20}
      // TODO(billy): Do deep object equality checking.
      return false;
    }
  }
  return true;
}


/**
 * Creates a zipped array where length is defined by smallest length array.
 * NOTE: non-mutating
 *
 * @param {...arrays} Arrays to zip.
 */
function zip() {
  // Cruft to make variable arguments into usable Array type.
  for (var _len = arguments.length, arrays = Array(_len), _key = 0; _key < _len; _key++) {
    arrays[_key] = arguments[_key];
  }

  // Grab the smallest length amongst the arrays.
  var len = Math.min.apply(null, arrays.map(function(a) {
    return a.length;
  }));

  // Do the actual zipping.
  return arrays[0].slice(0, len).map(function(_, n) {
    return arrays.map(function(a) {
      return a[n];
    });
  });
}

/**
 * Returns whether or not two arrays are equal, ignoring ordering of elements
 *
 * @param {!Array} ar1
 * @param {!Array} ar2
 * @param {Function=} opt_customSorter
 * @return {boolean} true if arrays contain the same elements, regardless of order
 */
function equalsIgnoreOrder(ar1, ar2, opt_customSorter) {
  if (ar1.length !== ar2.length) {
    return false;
  }

  if (opt_customSorter) {
    ar1.sort(opt_customSorter);
    ar2.sort(opt_customSorter);
  } else {
    ar1.sort();
    ar2.sort();
  }

  for (var i = 0; i < ar1.length; i++) {
    if (ar1[i] != ar2[i]) {
      return false;
    }
  }

  return true;
}

exports = {
  equals,
  zip,
  equalsIgnoreOrder,
};
