/**
 * @fileoverview Content Validator - validates the content of a survey model
 *
 * @author Samantha Landen (slanden@yext.com)
 */
goog.module('yext.surveyserving.ContentValidator');
goog.module.declareLegacyNamespace();

const yext = goog.require('yext');
const Model = goog.require('yext.mvc.Model');

/**
 * @constructor
 */
const ContentValidator = function() {
  /**
   * @type {!Array.<string>}
   * @private
   */
  this._errors = [];
};

/**
 * Validates the content of the model
 *
 * @param {Model} model
 */
ContentValidator.prototype.validate = function(model) {
  if (!(model instanceof Model)) {
    throw new TypeError('ContentValidator: invalid type, expected yext.mvc.Model');
  }
  this._errors = [];
  if (this.isStringEmpty(model.get('score'))) {
    this._errors.push('score');
  }
  if (this.isStringEmpty(model.get('message'))) {
    this._errors.push('message');
  }
  if (model.hasKey('author')
      && !model.get('pendingInvitationId')
      && this.isStringEmpty(model.get('author'))) {
    this._errors.push('author');
  }
  if (!this.isTrue(model.get('disclaimer'))) {
    this._errors.push('disclaimer');
  }
  if (model.hasKey('email')
      && !model.get('pendingInvitationId')
      && !this.isValidEmail(model.get('email'))) {
    this._errors.push('email');
  }
  // We only care about captcha if there is no invitation id present
  if (!model.get('pendingInvitationId')
      && !model.get('editSurvey')
      && !model.get('noCaptcha')
      && this.isStringEmpty(grecaptcha.getResponse())) {
    this._errors.push('captcha');
  }
  return this._errors;
};

/**
 * Checks if the given string is empty
 *
 * @param {string} input
 */
ContentValidator.prototype.isStringEmpty = function(input) {
  return !(!!input && yext.isString(input) && input.length > 0);
};

/**
 * Checks if the property with the given name is set to true
 *
 * @param {boolean} input
 */
ContentValidator.prototype.isTrue = function(input) {
  return yext.isBoolean(input) && input == true;
};

/**
 * Checks if the given string is a valid email address
 *
 * @param {string} email
 */
ContentValidator.prototype.isValidEmail = function(email) {
  return yext.EMAIL_REGEX.test(email);
};

exports = ContentValidator;
