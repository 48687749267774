/**
 * @fileoverview Survey Complete Page
 *
 * @author Samantha Landen (slanden@yext.com)
 */
goog.module('yext.surveyserving.SurveyComplete');
goog.module.declareLegacyNamespace();

const EditSurvey = goog.require('yext.surveyserving.EditSurvey');

/**
 * @constructor
 */
const SurveyComplete = function() {
  /**
   * @type {!jQuery}
   */
  this.$view = $('.js-view-container');

  /**
   * @type {!jQuery}
   */
  this.$edit = $('.js-edit-container');

  EditSurvey.call(this);
};
goog.inherits(SurveyComplete, EditSurvey);

/**
 * Initializes the page
 */
SurveyComplete.prototype.init = function() {
  SurveyComplete.superClass_.init.call(this);
  this.bindEdit();
};

/**
 * Binds toggling to edit mode
 */
SurveyComplete.prototype.bindEdit = function() {
  var self = this;
  this.$view.find('.js-edit-feedback').on('click', function() {
    self.$view.hide();
    self.$edit.show();
  });
};

goog.exportSymbol('yext.surveyserving.SurveyComplete', SurveyComplete);

exports = SurveyComplete;
