goog.provide('yext.fileupload');

goog.require('yext');

yext.fileupload.FILE_SELECTOR_ID = 1;
yext.fileupload.idString = 'fileSelector-';

/**
 * Builds a form element for use with the fileupload plugin
 *
 * @return {!jQuery}
 */
yext.fileupload.buildFileForm = function() {
  var field = document.createElement('form');
  var input = document.createElement('input');
  input.setAttribute('type', 'file');
  field.appendChild(input);
  return $(field);
};

/**
 * This method allows for files to be uploaded alongside data. Advantages of
 * using this instead of the plugin directly include DRYness and abstraction
 * of the upload logic so we can easily replace the plugin
 *
 * @param {!Object} options File upload options
 * @param {!jQuery} $uploadForm form to attach the fileupload functionality to
 */
yext.fileupload.upload = function(options) {
  var $form = yext.fileupload.buildFileForm();

  // Ensure some things are set properly

  if (typeof options !== 'object') {
    options = {};
  }

  if (typeof options.action !== 'string') {
    options.action = '';
  }

  if (Object.prototype.toString.call( options.data ) !== '[object Array]') {
    options.data = [];
  }

  $form.fileupload({
    'dropZone': null,
    'url': options.action,
    'dataType': options.dataType ? options.dataType : 'iframejson',
    'converters': yext.fileuploadIframeConverters,
    'start': function() {
      if (typeof options.start === 'function') {
        options.start();
      }
    },
    'done': function(e, data) {
      if (typeof options.done === 'function') {
        options.done(yext.fileupload.parseHtmlEncodedJson(data.result));
      }

      if (typeof options.always === 'function') {
        options.always();
      }
    },
    'fail': function(e, data) {
      if (typeof options.error === 'function') {
        options.error({
          status: data.jqXHR.status,
        });
      }

      if (typeof options.always === 'function') {
        options.always();
      }
    },
    'progress': function(e, data) {
      if (typeof options.progress === 'function') {
        options.progress(data.loaded, data.total);
      }
    },
  });

  var fileUploadAjax = $form.fileupload('send', {
    'formData': options.data,
    'files': options.files,
    'paramName': 'paramName' in options
      ? options.paramName
      : typeof options.filePrefix === 'string' ? options.filePrefix : 'file',
    'fileInput': options.fileInputs,
    'form': $form,
  });

  return fileUploadAjax;
};

/**
 * Creates a hidden file selector
 *
 * @return {!Object} A lightweight object for selecting files
 */
yext.fileupload.fileSelector = function() {
  var $form = yext.fileupload.buildFileForm();
  var $file = $form.find('input[type=file]');
  var selector = {
    id: yext.fileupload.FILE_SELECTOR_ID++,
    $form: $form,
    selectFile: function() {
      $file.trigger('click');
    },
    destroy: function() {
      $(this).off('fileAdded');
    },
    // Target element must be of type label
    targetElement: function($el) {
      if (!$el.is('label')) {
        throw new Error('Target element must be a label for the file selector to work properly');
      }
      var id = yext.fileupload.idString + selector.id;
      $('[for=' + id + ']').removeAttr('for');
      $el.attr('for', id);
      $el.append($form);
    },
  };

  $file.attr('id', yext.fileupload.idString + selector.id);
  $('body').append($form.hide());

  $file.on('change', function() {
    if ($file[0].files.length > 0) {
      $(selector).trigger('fileAdded', [$file[0].files, [$file]]);
    }
  });

  return selector;
};

/**
 * Takes HTML encoded json and turns it back into JSON
 *
 * TODO(mattjs) Look into this and see if it can be fixed across LocationEdit
 *
 * @param {string} json JSON that may be html encoded
 * @return {!Object} JSON
 */
yext.fileupload.parseHtmlEncodedJson = function(json) {
  if (!yext.isObject(json) && yext.isString(json)) {
    json = $.parseJSON($('<div/>').html(json).text());
  }
  return json;
};
