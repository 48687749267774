goog.module('yext.tracing.metrics');

/**
 * Calculates useful latency metrics using the given
 * performance timing interface.
 *
 * @param {PerformanceTiming | PerformanceNavigationTiming} perf
 * @return {!Object} Timing metrics.
 */
function _calculateTimingMetrics(perf) {
  const metrics = {};

  // resource timing
  metrics['browser.dns_lookup_ms'] = perf.domainLookupEnd - perf.domainLookupStart;
  metrics['browser.tcp_connection_ms'] = perf.connectEnd - perf.connectStart;
  metrics['browser.redirect_ms'] = perf.redirectEnd - perf.redirectStart;

  let tlsTime = 0;
  if (perf.secureConnectionStart > 0) {
    // note: tls negotiation ends at the same time as connect end
    tlsTime = perf.connectEnd - perf.secureConnectionStart;
  }
  metrics['browser.tls_negotiation_ms'] = tlsTime;

  metrics['browser.request_ms'] = perf.responseStart - perf.requestStart;
  metrics['browser.round_trip_time_ms'] = perf.responseEnd - perf.requestStart;
  metrics['browser.response_download_ms'] = perf.responseEnd - perf.responseStart;
  metrics['browser.unload_ms'] = perf.unloadEventEnd - perf.unloadEventStart;

  metrics['browser.network_latency_ms'] = perf.responseEnd - perf.fetchStart > 0 ? perf.responseEnd - perf.fetchStart : 0;

  // document processing
  metrics['browser.dom_content_loaded_ms'] = perf.domContentLoadedEventEnd - perf.domContentLoadedEventStart;
  if (perf.domLoading) {
    metrics['browser.dom_content_complete_ms'] = perf.domContentLoadedEventEnd - perf.domLoading;
    metrics['browser.dom_complete_ms'] = perf.domComplete - perf.domLoading;
  }

  // js processing
  metrics['browser.dom_load_event_ms'] = perf.loadEventEnd - perf.loadEventStart;

  // user experience
  metrics['browser.dom_interactive_ms'] = perf.domInteractive - perf.requestStart;
  metrics['browser.processing_latency_ms'] = perf.loadEventEnd - perf.responseEnd > 0 ? perf.loadEventEnd - perf.responseEnd : 0;

  // body + headers
  if (perf.transferSize) {
    metrics['browser.response_size_bytes'] = perf.transferSize;
  }

  // headers
  if (perf.transferSize && perf.encodedBodySize) {
    metrics['browser.header_size_bytes'] = perf.transferSize - perf.encodedBodySize;
  }

  // body
  if (perf.encodedBodySize) {
    metrics['browser.body_size_bytes'] = perf.encodedBodySize;
  }

  if (perf.encodedBodySize && perf.decodedBodySize) {
    metrics['browser.body_compression_ratio'] = perf.decodedBodySize / perf.encodedBodySize;
  }

  metrics['browser.bandwidth_bucket'] = getBandwidthBucket();

  return metrics;
}

/**
 * Get the bandwidth bucket from the W3C effective connection type. Currently only supported on
 * chrome.
 *
 * @return {int} A int indication of the W3C effective connection type.
 */
function getBandwidthBucket() {
  const networkInfo = navigator.connection
    || navigator.mozConnection
    || navigator.webkitConnection
    || navigator.msConnection
    || {};
  const effectiveType = networkInfo.effectiveType;
  switch (effectiveType) {
    case '4g':
      return 4;
    case '3g':
      return 3;
    case '2g':
      return 2;
    case 'slow-2g':
      return 1;
    default:
      return 0;
  }
}

/**
 * Calculates latency metrics based on the available
 * performance timing interface.
 *
 * @return {!Object} Various latency metrics.
 */
exports.calculateTimingMetrics = function() {
  let metrics = {};
  if (window.performance) {
    if ('PerformanceNavigationTiming' in window) {
      const [perf] = performance.getEntriesByType('navigation');
      metrics = _calculateTimingMetrics(perf);
      metrics['browser.navigation_start_time'] = performance.timeOrigin;
    } else if ('PerformanceTiming' in window) {
      const perf = performance.timing;
      metrics = _calculateTimingMetrics(perf);
      metrics['browser.navigation_start_time'] = perf.navigationStart;
    }
  }
  return metrics;
};
