/**
 * @fileoverview Edit Survey Page - handles form validation and page setup
 *
 * @author Samantha Landen (slanden@yext.com)
 */
goog.module('yext.surveyserving.EditSurvey');
goog.module.declareLegacyNamespace();

const yext = goog.require('yext');
const Survey = goog.require('yext.surveyserving.Survey');

/**
 * @constructor
 */
const EditSurvey = function() {
  Survey.call(this);
};
goog.inherits(EditSurvey, Survey);

/**
 * Initialize the model
 */
EditSurvey.prototype.init = function() {
  EditSurvey.superClass_.init.call(this);
  this.bindRemoveReview();
};

/**
 * Initialize the model
 */
EditSurvey.prototype.initModel = function() {
  this._model.init({
    'score': this.$form.find('.js-score:checked').val(),
    'message': this.$form.find('.js-message').val(),
    'disclaimer': true,
    'editSurvey': true,
  });
};

/**
 * Binds action taken upon clicking the "Remove Review" button
 */
EditSurvey.prototype.bindRemoveReview = function() {
  var self = this;
  this.$form.find('.js-remove-feedback').on('click', function() {
    $.ajax({
      'type': 'DELETE',
    }).done(function(data) {
      if (data['success']) {
        self.$form.html(data['html']);
      } else {
        alert(yext.msg('There was an error removing your review. Please try again later.'));
      }
    }).fail(function() {
      alert(yext.msg('There was an error removing your review. Please try again later.'));
    });
  });
};

goog.exportSymbol('yext.surveyserving.EditSurvey', EditSurvey);

exports = EditSurvey;
