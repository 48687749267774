goog.module('yext.mvc.EventEmitter');
goog.module.declareLegacyNamespace();

/**
 * TODO(billy) Implement own pub/sub event mechanism
 * instead of depending on jQuery
 *
 * @constructor
 */
const EventEmitter = function() {};

/**
 * emit will broadcast a message on the object itself,
 * over the jQuery channel, providing the current model as the first argument
 */
EventEmitter.prototype.emit = function(eventNames) {
  // We remove the first element from the arguments (e.g. event name)
  var args = Array.prototype.slice.call(arguments, 1);
  var events = eventNames.split(',');

  var $that = $(this);
  for (var i = 0; i < events.length; i ++) {
    $that.trigger($.trim(events[i]), args);
  }
};

EventEmitter.prototype.on = function(eventNames, handler) {
  var events = eventNames.split(',');

  var $that = $(this);
  for (var i = 0; i < events.length; i ++) {
    $that.on($.trim(events[i]), handler);
  }
  return this;
};

// TODO remove all events if no name is provided
EventEmitter.prototype.off = function(eventNames, handler) {
  var events = eventNames.split(',');

  var $that = $(this);
  for (var i = 0; i < events.length; i ++) {
    $that.off($.trim(events[i]), handler);
  }
  return this;
};

exports = EventEmitter;
