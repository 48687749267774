/**
 * {Model} provides a basic interface for managing the state of your application,
 * while exposing a interface for views (and other components) to interact with the changes of the state.
 *
 * @author Billy Bastardi (billy@yext.com)
 */
goog.module('yext.mvc.Model');
goog.module.declareLegacyNamespace();

const yext = goog.require('yext');
const arrays = goog.require('yext.utils.arrays');
const EventEmitter = goog.require('yext.mvc.EventEmitter');

/**
 * @constructor
 * @extends {EventEmitter}
 * @param {!Object=} opts
 */
const Model = function(opts) {
  EventEmitter.call(this);

  opts = opts || {};

  /**
   * The unique identifer of the model of the model
   */
  this['id'] = opts['id'] || null;

  /**
   * A model's unique identifier is stored under the id attribute.
   * If you're directly communicating with a backend that uses a different unique key,
   * you may set a Model's idAttribute to transparently map from that key to id.
   * @type {string}
   */
  this['idAttribute'] = opts['idAttribute'] || 'id';

  /**
   * Keeps track of which fields were changed from the previous state
   * @type {!Object}
   * @private
   */
  this['_changes'] = {};

  /**
   * References the state of the object prior to the last set 'action'.
   * @type {!Object}
   * @private
   */
  this['_previousState'] = null;
};
goog.inherits(Model, EventEmitter);

// Fields listed here are stripped from the final serialized object.
// That way, we can reduce the amount of payload sent to servers.
Model.EXPORT_IGNORE_FIELDS = [
  'idAttribute',
];

/**
 * shouldExportField will check if a field should be exported
 * @param {string} key
 * @return {boolean} true if the field is exportable
 */
Model.shouldExportField = function(key) {
  // Ignore private fields, or any fields hardcoded in the list
  return key[0] !== '_'
         && key.indexOf('jQuery') != 0
         && Model.EXPORT_IGNORE_FIELDS.indexOf(key) < 0;
};


/**
 * initializer, providing a way to apply default state of an object
 * @param {!Model} model
 */
Model.prototype.init = function(model) {
  this.set(model);

  if (this['idAttribute'] !== 'id') {
    delete this['id'];
  }

  // Previous state will be null during the initialization.
  // thus, we set it to the value of the initial state
  this._captureStateAsPrevious();

  return this;
};

/**
 * set enables you to change the state of the model.
 *
 * A 'change' event will be broadcast on the model itself, upon success.
 *
 * @param {string|object} key The property to assign a value to, or an object literal
 *                            containing key value pairs
 * @param {T} val The value to set the key to
 */
Model.prototype.set = function(key, val) {
  this._captureStateAsPrevious();
  this._set(key, val);

  if (this.hasChanges()) {
    this.emit('change', this);
    this.emitPropertyChanges('change');
  }
};

/**
 * resetState enables you to reset the state of a model without broadcasting a `change` event.
 *
 * A 'reset' event will be broadcast on the model itself, upon success.
 *
 * #usage
 *    model.resetState();
 *    model.resetState('property');
 *    model.resetState({ // properties });
 *
 * @param {string|object} key The property to assign a value to, or an object literal
 *                            containing key value pairs
 * @param {T} val The value to set the key to
 */
Model.prototype.resetState = function(key, val) {
  // If reset is called with no arguments, just reset to previous state.
  if (!key && !val) {
    this._set(this['_previousState']);
  } else if (key && !yext.isObject(key) && val === undefined) {
    // Otherwise, if it's just trying to reset one key to previous state, do it.
    this._set(key, this['_previousState'][key]);
  } else {
    // Otherwise, if you're trying to reset to a new value
    this._set(key, val);
  }

  if (this.hasChanges()) {
    this.emit('reset', this);
    this.emitPropertyChanges('reset');
  }
};

/**
 * Determines if we're using an object literal for set, or a single key-val.
 * and apply it accordingly to the model.
 * @param {string|object} key The property to assign a value to, or an object literal
 *                            containing key value pairs
 * @param {T} val The value to set the key to
 * @private
 */
Model.prototype._set = function(key, val) {
  this._clearChanges();

  // Assume we have an object literal
  if (yext.isObject(key)) {
    for (var k in key) {
      if (key.hasOwnProperty(k)) {
        this._setKey(k, key[k]);
      }
    }
  } else {
    this._setKey(key, val);
  }
};

/**
 * setKey is used to modify the state of a single property on the model
 * @param {string} key The property to assign a value to
 * @param {T} val The value to be assigned to the property
 * @private
 */
Model.prototype._setKey = function(key, val) {
  // If we use the core model class, allow any properties to be set.
  if (this.constructor !== Model
      && yext.isString(key) && !this.hasOwnProperty(key)) {
    throw new Error(this.constructor.toString() + ': Can not set "' + key + '", property does not exist on class!');
  }

  this._trackChange(key, val);
  this[key] = val;
};

Model.prototype.get = function(key) {
  if (key === 'id') {
    return this[this['idAttribute']];
  }
  return this[key];
};

/**
 * @param {string} key
 * @return true if the model has the key
 */
Model.prototype.hasKey = function(key) {
  return this.hasOwnProperty(key);
};

/**
 * Compare the current model state to a different object,
 * returning the delta. It tracks missing properties and value differences separately.
 * @param {!Object} comparedObj the object to compare against
 * @return {!Object} differences between comparedObj and model class
 */
Model.prototype.findDelta = function(comparedObj) {
  var delta = {};
  var hasDelta = false;
  var missingProperties = {};
  var missingPropertyCount = 0;

  for (var k in comparedObj) {
    // Properties must exist on our class, otherwise
    // they are not considered 'delta', they are considered 'missing'.
    if (this.hasOwnProperty(k) && this[k] !== comparedObj[k]) {
      delta[k] = comparedObj[k];
      hasDelta = true;
    } else {
      missingProperties[k] = comparedObj[k];
      missingPropertyCount ++;
    }
  }
  return {
    delta: delta,
    hasDelta: hasDelta,
    missingProperties: missingProperties,
    hasMissingProperties: missingPropertyCount > 0,
  };
};

Model.prototype.getChanges = function() {
  return this['_changes'];
};

Model.prototype.hasChanges = function() {
  return yext.size(this['_changes']) > 0;
};

Model.prototype._clearChanges = function() {
  this['_changes'] = {};
};

Model.prototype._trackChange = function(k, v) {
  // Equality checks on arrays are a little more complicated
  // so we handle them differently from other primitives.
  // TODO(billy) Object equality
  if (Array.isArray(this[k]) && !arrays.equals(this[k], v)) {
    this['_changes'][k] = v;
  } else {
    if (this[k] !== v) {
      this['_changes'][k] = v;
    }
  }
};

Model.prototype._captureStateAsPrevious = function() {
  this._previousState = this.raw();
};

/**
 * Broadcast a change for each property that was changed
 * This supports use cases:
 *    m.on('change:property', function() { ... });
 * @param {string} eventName The event name to use as the prefix for each property event
 */
Model.prototype.emitPropertyChanges = function(eventName) {
  for (var changedProp in this.getChanges()) {
    this.emit(eventName + ':' + changedProp, this);
  }
};

/**
 * raw will provide a new object representing the current one,
 * with all the private and unexportable fields stripped.
 * @returns {!Object}
 */
Model.prototype.raw = function() {
  var raw = {};
  for (var k in this) {
    if (!this.hasOwnProperty(k)) {
      continue;
    }

    if (!Model.shouldExportField(k)) {
      continue;
    }
    raw[k] = this[k];
  }
  return raw;
};

/**
 * asJSON avoids all the properties unexportable fields from
 * getting serialized as a JSON string
 * @return {string}
 */
Model.prototype.asJSON = function() {
  return yext.JSON.stringify(this.raw());
};

Model.prototype.equals = function(other) {
  return other instanceof Model && this.get('id') == other.get('id');
};

exports = Model;
